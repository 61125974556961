<template>
  <dog-dialog
    ref="dogDialog"
    width="900px"
    title="设置公司"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <div class="flex_row dialog_zone_scroll setCompanyDialog">
      <div class="flex_1 flex_column">
        <dog-search
          ref="search"
          :config="searchConfig"
          @search="search"
        ></dog-search>
        <dog-table
          ref="dogTable"
          id="34f7bc6a-a5bb-4ace-b337-972d42856995"
          row-key="id"
          :columns="tableColumns"
          :service="getList"
          :column-type="columnType"
          @selection-change="selectedData = $event"
        >
        </dog-table>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="setCompanyDialog__selected">
        <h4>已选：</h4>
        <dog-scroll-box class="setCompanyDialog__selected__tags">
          <el-tag
            size="small"
            closable
            @close="remove(user)"
            v-for="user in selectedData"
            :key="user.id"
            >{{ user.name }}</el-tag
          >
        </dog-scroll-box>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import tenantService from '@/api/service/tenant.js';
  import companyService from '@/api/service/company';

  export default {
    name: 'setCompanyDialog',
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '用户名',
            key: 'userName'
          },
          {
            type: 'input',
            label: '真实姓名',
            key: 'realName'
          }
        ],
        tableColumns: [
          { prop: 'name', label: '公司名', width: 200 },
          { prop: 'type_cn', label: '类型', width: 100, widthGrow: false },
          { prop: 'desc', label: '简介', width: 200 }
        ],
        columnType: [
          {
            type: 'selection',
            props: {
              reserveSelection: true
            }
          }
        ],
        selectedData: [],
        tenantId: null
      };
    },
    methods: {
      openFrame(tenantId) {
        this.tenantId = tenantId;
        this.$refs.dogDialog.open();
        tenantService.getTenantCompany({ tenantId }).then((res) => {
          for (let i = 0; i < res.length; i++) {
            this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
              res[i],
              true
            );
          }
          this.searchData();
        });
      },
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return companyService.getCompanyList(params).then((res) => {
          res.list.forEach((item) => {
            for (let i = 0; i < this.selectedData.length; i++) {
              // 所选数据重新赋值为列表数据，否则选中效果渲染不出来
              if (this.selectedData[i].id === item.id) {
                this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
                  this.selectedData[i],
                  false
                );
                this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
                  item,
                  true
                );
                break;
              }
            }
          });
          return res;
        });
      },
      remove(user) {
        this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
          user,
          false
        );
      },
      confirm(done) {
        tenantService
          .setTenantCompany({
            tenantId: this.tenantId,
            companyIds: this.selectedData.map((item) => item.id)
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.selectedData = [];
        this.tenantId = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(setCompanyDialog) {
    @include e(selected) {
      width: 150px;
      $h4_margin: 10px;
      $h4_height: 16px;
      h4 {
        margin-top: $h4_margin;
        margin-bottom: $h4_margin;
        line-height: $h4_height;
      }
      @include e(tags) {
        height: calc(100% - #{$h4_height} - #{$h4_margin} - #{$h4_margin});
      }
      /deep/ .el-tag {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
</style>
