import baseService from '../baseService';
import enumService from './enum';
const request = baseService('company');
import { array_find } from '@/utils/methods/common';

export default {
  getCompanyList(params) {
    return Promise.all([
      request('/getCompanyList', params, 'get'),
      enumService('companyType')()
    ]).then((res) => {
      res[0].list.forEach((item) => {
        item.type_cn = array_find(res[1], 'value', item.type).label;
      });
      return res[0];
    });
  },
  deleteCompanies(params) {
    return request('/deleteCompanies', params, 'delete');
  },
  addCompany(params) {
    return request('/addCompany', params, 'post');
  },
  editCompany(params) {
    return request('/editCompany', params, 'put');
  },
  addCompanySimple(params) {
    return request('/addCompanySimple', params, 'post');
  },
  editCompanySimple(params) {
    return request('/editCompanySimple', params, 'put');
  },
  setCompanyTenantIds(params) {
    return request('/setCompanyTenantIds', params, 'post');
  },
  getCompanyTenantIds(params) {
    return request('/getCompanyTenantIds', params, 'get');
  },
  getCompanyDetail(params) {
    return request('/getCompanyDetail', params, 'get');
  },
  setBanner(params) {
    return request('/setBanner', params, 'post');
  },
  getBanner(params) {
    return request('/getBanner', params, 'get');
  },
  setCompanyHistory(params) {
    return request('/setCompanyHistory', params, 'post');
  },
  getCompanyHistory(params) {
    return request('/getCompanyHistory', params, 'get');
  },
  setCompanyTrend(params) {
    return request('/setCompanyTrend', params, 'post');
  },
  getCompanyTrend(params) {
    return request('/getCompanyTrend', params, 'get');
  },
  getOwnCompanyList(params) {
    return Promise.all([
      request('/getOwnCompanyList', params, 'get'),
      enumService('companyType')()
    ]).then((res) => {
      res[0].list.forEach((item) => {
        item.type_cn = array_find(res[1], 'value', item.type).label;
      });
      return res[0];
    });
  },
  getOwnCompanyEnum(params) {
    return request('/getOwnCompanyEnum', params, 'get').then((res) => {
      return res.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id
        };
      });
    });
  },
  getAllCompany(params) {
    return request('/getAllCompany', params, 'get').then((res) => {
      return res.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id
        };
      });
    });
  }
};
