import baseService from '../baseService';
import enumService from './enum';
import { array_find } from '@/utils/methods/common';
const request = baseService('tenant');

export default {
  getTenantList(params) {
    return Promise.all([
      request('/getTenantList', params, 'get'),
      enumService('sex')()
    ]).then((res) => {
      res[0].list.forEach((item) => {
        item.sex_cn = array_find(res[1], 'value', item.sex).label;
      });
      return res[0];
    });
  },
  deleteTenants(params) {
    return request('/deleteTenants', params, 'delete');
  },
  addTenant(params) {
    return request('/addTenant', params, 'post');
  },
  setTenantCompany(params) {
    return request('/setTenantCompany', params, 'post');
  },
  getTenantCompany(params) {
    return request('/getTenantCompany', params, 'get');
  }
};
